<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Register v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <b-img alt="logo" src="/login_logo.png">
            <h2 class="brand-text text-primary ml-1">
              한국건설기술연구원
            </h2>
          </b-img>
        </b-link>

        <b-card-title class="mb-1">
          회원가입
        </b-card-title>

        <!-- form -->
        <validation-observer ref="joinForm">
          <b-form class="auth-register-form mt-2" @submit.prevent>
            <!-- email -->
            <b-form-group label="아이디(이메일)" label-for="userId">
              <validation-provider
                #default="{ errors }"
                name="아이디"
                rules="required|email"
              >
                <b-form-input
                  id="userId"
                  v-model="formData.userId"
                  :state="errors.length > 0 ? false : null"
                  name="register-email"
                  placeholder="john@example.com"
                />
                <small v-if="errors.length > 0" class="text-danger">
                  이메일(아이디)은 필수입니다.
                </small>
              </validation-provider>
            </b-form-group>
            <!-- username -->
            <b-form-group label="이름" label-for="username">
              <validation-provider
                #default="{ errors }"
                name="이름"
                rules="required"
              >
                <b-form-input
                  id="username"
                  v-model="formData.username"
                  :state="errors.length > 0 ? false : null"
                  name="register-username"
                  placeholder="johndoe"
                />
                <small v-if="errors.length > 0" class="text-danger">
                  이름은 필수입니다.
                </small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group label="비밀번호" label-for="password">
              <validation-provider
                #default="{ errors }"
                name="비밀번호"
                rules="required"
              >
                <b-input-group
                  :class="errors.length > 0 ? 'is-invalid' : null"
                  class="input-group-merge"
                >
                  <b-form-input
                    id="password"
                    v-model="formData.password"
                    :state="errors.length > 0 ? false : null"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    name="password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small v-if="errors.length > 0" class="text-danger"
                  >비밀번호 필수입니다.</small
                >
              </validation-provider>
            </b-form-group>

            <!-- passwordCheck -->
            <b-form-group label="비밀번호 확인" label-for="passwordCheck">
              <validation-provider
                #default="{ errors }"
                name="비밀번호 확인"
                rules="required|confirmed:비밀번호"
              >
                <b-input-group
                  :class="errors.length > 0 ? 'is-invalid' : null"
                  class="input-group-merge"
                >
                  <b-form-input
                    id="passwordCheck"
                    v-model="formData.passwordCheck"
                    :state="errors.length > 0 ? false : null"
                    :type="passwordCheckFieldType"
                    class="form-control-merge"
                    name="passwordCheck"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordCheckToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordCheckVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small v-if="errors.length > 0" class="text-danger"
                  >비밀번호가 다릅니다.</small
                >
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="동의"
                rules="required"
              >
                <b-form-checkbox
                  id="register-privacy-policy"
                  v-model="formData.status"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                  class="p-0"
                  name="checkbox-1"
                  plain
                  value="Y"
                >
                  회원가입에 동의하십니까?
                </b-form-checkbox>
                <small v-if="errors.length > 0" class="text-danger"
                  >회원가입 동의 여부를 선택해주세요.
                </small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              block
              class="btn-join"
              type="submit"
              @click="validationForm"
            >
              회원가입
            </b-button>
            <b-card-text class="text-center mt-2">
              <b-link :to="{ path: '/auth-login' }">
                <feather-icon icon="ChevronLeftIcon" />
                Back to login
              </b-link>
            </b-card-text>
          </b-form>
        </validation-observer>
      </b-card>
      <!-- /Register v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import {
  BButton,
  BCard,
  BCardText,
  BCardTitle,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BLink,
} from 'bootstrap-vue';
import { email, required } from '@validations';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';

export default {
  components: {
    BImg,
    // BSV
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      formData: {
        userId: 'user1@edoc.com',
        username: '유저1',
        password: '11',
        passwordCheck: '11',
        status: [],
      },

      // validation rules
      required,
      email,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    passwordCheckToggleIcon() {
      return this.passwordCheckFieldType === 'password'
        ? 'EyeIcon'
        : 'EyeOffIcon';
    },
  },
  methods: {
    validationForm() {
      this.$refs.joinForm.validate().then(async (success) => {
        if (success) {
          // 회원 가입 처리
          const res = await this.$store.dispatch('auth/FETCH_AUTH_JOIN', {
            userId: this.formData.userId,
            username: this.formData.username,
            password: this.formData.password,
            passwordCheck: this.formData.passwordCheck,
          });
          if (res.resultFlag) {
            alert(res.resultMsg);
          } else {
            alert(res.resultMsg);
          }
          // await this.$router.push({ path: "/building-service" });
          window.location.href = '/';
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.btn-join,
.btn-secondary:focus {
  color: #fff !important;
  background-color: #f49f17 !important;
  border-color: #f49f17 !important;
}
</style>
