var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1 px-2"},[_c('div',{staticClass:"auth-inner py-2"},[_c('b-card',{staticClass:"mb-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('b-img',{attrs:{"alt":"logo","src":"/login_logo.png"}},[_c('h2',{staticClass:"brand-text text-primary ml-1"},[_vm._v(" 한국건설기술연구원 ")])])],1),_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" 회원가입 ")]),_c('validation-observer',{ref:"joinForm"},[_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-form-group',{attrs:{"label":"아이디(이메일)","label-for":"userId"}},[_c('validation-provider',{attrs:{"name":"아이디","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"userId","state":errors.length > 0 ? false : null,"name":"register-email","placeholder":"john@example.com"},model:{value:(_vm.formData.userId),callback:function ($$v) {_vm.$set(_vm.formData, "userId", $$v)},expression:"formData.userId"}}),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v(" 이메일(아이디)은 필수입니다. ")]):_vm._e()]}}])})],1),_c('b-form-group',{attrs:{"label":"이름","label-for":"username"}},[_c('validation-provider',{attrs:{"name":"이름","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"username","state":errors.length > 0 ? false : null,"name":"register-username","placeholder":"johndoe"},model:{value:(_vm.formData.username),callback:function ($$v) {_vm.$set(_vm.formData, "username", $$v)},expression:"formData.username"}}),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v(" 이름은 필수입니다. ")]):_vm._e()]}}])})],1),_c('b-form-group',{attrs:{"label":"비밀번호","label-for":"password"}},[_c('validation-provider',{attrs:{"name":"비밀번호","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","state":errors.length > 0 ? false : null,"type":_vm.passwordFieldType,"name":"password","placeholder":"············"},model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", $$v)},expression:"formData.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v("비밀번호 필수입니다.")]):_vm._e()]}}])})],1),_c('b-form-group',{attrs:{"label":"비밀번호 확인","label-for":"passwordCheck"}},[_c('validation-provider',{attrs:{"name":"비밀번호 확인","rules":"required|confirmed:비밀번호"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"passwordCheck","state":errors.length > 0 ? false : null,"type":_vm.passwordCheckFieldType,"name":"passwordCheck","placeholder":"············"},model:{value:(_vm.formData.passwordCheck),callback:function ($$v) {_vm.$set(_vm.formData, "passwordCheck", $$v)},expression:"formData.passwordCheck"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordCheckToggleIcon},on:{"click":_vm.togglePasswordCheckVisibility}})],1)],1),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v("비밀번호가 다릅니다.")]):_vm._e()]}}])})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"동의","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{staticClass:"p-0",class:errors.length > 0 ? 'is-invalid' : null,attrs:{"id":"register-privacy-policy","name":"checkbox-1","plain":"","value":"Y"},model:{value:(_vm.formData.status),callback:function ($$v) {_vm.$set(_vm.formData, "status", $$v)},expression:"formData.status"}},[_vm._v(" 회원가입에 동의하십니까? ")]),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v("회원가입 동의 여부를 선택해주세요. ")]):_vm._e()]}}])})],1),_c('b-button',{staticClass:"btn-join",attrs:{"block":"","type":"submit"},on:{"click":_vm.validationForm}},[_vm._v(" 회원가입 ")]),_c('b-card-text',{staticClass:"text-center mt-2"},[_c('b-link',{attrs:{"to":{ path: '/auth-login' }}},[_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon"}}),_vm._v(" Back to login ")],1)],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }